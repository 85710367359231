<template>
  <div class="file-sharer">
    <a-modal v-model="visible" title="Share file" :footer="null" :afterClose="handleAfterClose" :zIndex="9999">
      <a-spin v-if="!success" :spinning="loading" tip="Generate share link...">
        <a-form-model :model="form" :rules="rules" ref="modelRef">
          <a-form-model-item label="File name">
            <a-input v-model="form.originalName" disabled />
          </a-form-model-item>
          <a-form-model-item label="Extract code" prop="fetchCode">
            <a-input v-model="form.fetchCode" :maxLength="4" />
          </a-form-model-item>
          <a-form-model-item label="Effective time" prop="survivalTime">
            <a-radio-group v-model="form.survivalTime">
              <a-radio :value="1 | dayFilter">
                one day
              </a-radio>
              <a-radio :value="7 | dayFilter">
                seven days
              </a-radio>
              <a-radio :value="30 | dayFilter">
                thirty days
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="handleCreateShare" :loading="loading">
              create a share link
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </a-spin>
      <a-result v-else title="File shared">
        <template #icon>
          <a-icon type="smile" theme="twoTone" />
        </template>
        <template #extra>
          <a-space>
            <a-button type="primary" v-copy="{ text: link, callback: copyCallback }">
              copy share link
            </a-button>
            <a-button @click="handleToSharePage">jump to share page</a-button>
          </a-space>

        </template>
      </a-result>
    </a-modal>
  </div>
</template>

<script>
import { copy } from '@/directives'

export default {
  name: 'file-sharer',
  data() {
    return {
      visible: false,
      callback: undefined,
      form: {
        fetchCode: undefined,
        survivalTime: 1 * 60 * 24,
        userResourceId: undefined,
        originalName: undefined,
        type: undefined
      },
      rules: {
        survivalTime: [
          {
            required: true,
            message: 'Select a validity period for resource sharing',
            trigger: 'change'
          }
        ],
        fetchCode: [
          {
            required: true,
            message: 'Please enter the resource extraction code (4 digits)',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 4,
            message: 'Extraction code length is 4 bits (fixed value)'
          }
        ]
      },
      loading: false,
      success: false,
      link: undefined
    }
  },
  methods: {
    open(file, callback) {
      this.form.type = file.type === 'folder' ? 0 : 1
      this.form.originalName = file.name
      this.form.userResourceId = file.id

      this.callback = callback
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleCreateShare() {
      this.loading = true
      this.$refs.modelRef?.validate(valid => {
        if (valid) {
          this.$store.dispatch('file/createShare', this.form).then(response => {
            this.link = window.location.host + '/share-details/' + response
            this.callback && this.callback()
            this.loading = false
            this.success = true
          })
        }
      })
    },
    handleAfterClose() {
      this.success = false
      this.form.fetchCode = undefined
    },
    copyCallback() {
      this.$message.success('Copy successfully, quickly share this resource with your friends')
    },
    handleToSharePage() {
      this.$router.push('/share')
    }
  },
  filters: {
    dayFilter(day) {
      return day * 60 * 24
    }
  },
  directives: {
    copy
  }
}
</script>

<style scoped lang="less">

</style>
