<template>
  <div class="file-mover">
    <a-modal v-model="visible" title="Save to" :bodyStyle="{ 'max-height': '480px', 'overflow-y': 'scroll', }">
      <file-breadcrumb :items="routeStack" separator=">" :prefix="{ id: 0, name: 'file' }"
        :clickFn="handleBreadcrumbClick" class="breadcrumb" />
      <a-spin :spinning="loading" tip="Loading">
        <div class="create-folder" v-show="showCreateFolder">
          <a-input class="create-folder-input" v-model="folderName" ref="createFolderRef"></a-input>
          <a-space style="margin-right: 4px">
            <a-tooltip>
              <template slot="title">
                Cancel
              </template>
              <a-icon type="close-circle" class="action-icon" @click="closeCreateFolder" />
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                Save
              </template>
              <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" class="action-icon"
                @click="handleCreateFolder" />
            </a-tooltip>
          </a-space>
        </div>
        <div v-if="fileList.length" class="file-list">
          <div :class="[file.type === 'folder' && file.id !== activeFile.id ? 'save-item' : 'disable']"
            v-for="file in fileList" :key="file.id" @click="handleFileClick(file)" :title="file.name">
            <img :src="typeMapper[file.extension] || typeMapper.fallback" class="file-cover">
            <div class="file-name">{{ file.name }}</div>
          </div>
        </div>
        <a-empty v-else :image="simpleImage" description="Empty folder" />
      </a-spin>
      <template slot="footer">
        <a @click="openCreateFolder">New folder</a>
        <a-space>
          <a-button @click="close">
            Cancel
          </a-button>
          <a-button type="primary" :loading="submitting" @click="handleMove">
            Save here
          </a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';
import FileBreadcrumb from '@views/file-list/components/breadcrumb'

export default {
  name: 'file-mover',
  inject: ['typeMapper'],
  components: {
    FileBreadcrumb
  },
  data() {
    return {
      visible: false,
      loading: false,
      files: [],
      folders: [],
      parentId: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      routeStack: [],
      showCreateFolder: true,
      folderName: undefined,
      activeFile: {},
      callback: undefined,
      submitting: false
    }
  },
  created() {
    this.getData(0)
  },
  methods: {
    getData(parentId) {
      this.loading = true
      this.showCreateFolder = false
      this.parentId = parentId
      const payload = {
        parentId
      }
      this.getFolderPath()
      this.$store.dispatch('file/getUserFile', payload).then(response => {
        this.files = response.resources
        this.folders = response.folders
        this.loading = false
      })
    },
    open(file, callback) {
      this.activeFile = file
      this.callback = callback
      this.visible = true
    },
    close() {
      this.visible = false
    },
    openCreateFolder() {
      this.folderName = undefined
      this.showCreateFolder = true
      this.$nextTick(() => {
        this.$refs.createFolderRef?.focus()
      })
    },
    closeCreateFolder() {
      this.showCreateFolder = false
    },
    getFolderPath() {
      const parentId = this.parentId
      const payload = {
        parentId
      }
      if (parentId == '0') {
        this.routeStack = []
      }
      else {
        this.$store.dispatch('file/getFolderPath', payload).then(response => {
          this.processRelations(response)
        })
      }
    },
    processRelations(relation) {
      const stack = []
      let bottomLevel = relation
      while (bottomLevel) {
        const el = {
          name: bottomLevel.name,
          id: bottomLevel.id
        }
        stack.unshift(el)
        bottomLevel = bottomLevel.parent
      }
      this.routeStack = stack
    },
    handleBreadcrumbClick(item) {
      this.getData(item.id)
    },
    handleCreateFolder() {
      const isValid = this.folderName !== undefined && this.folderName?.trim() !== ''
      if (!isValid) {
        return this.$message.error('The folder name cannot be empty')
      }
      else {
        const payload = {
          folderName: this.folderName,
          parentId: this.parentId
        }
        this.$store.dispatch('file/createFolder', payload).then((response) => {
          this.getData(this.parentId)
        })
      }
    },
    handleFileClick(file) {
      const { type, id } = file
      if (type === 'folder' && file.id !== this.activeFile.id) {
        this.getData(id)
      }
    },
    handleMove() {
      this.submitting = true
      const { type, id, name } = this.activeFile
      const parentId = this.parentId
      const payload = {
        parentId,
        id,
        name
      }
      this.$parent.handleMoveFile(type, payload).then(() => {
        this.callback && this.callback()
        const content = h => {
          return h('a', {
            attrs: {
              href: `/file-list/${parentId}`
            }
          }, 'Move successfully, click to view')
        }

        this.$message.success(content, 5)
      }).finally(() => {
        this.close()
        this.submitting = false
      })
    }
  },
  computed: {
    fileList() {
      this.files.forEach((file) => {
        file.name = file.fileName
        file.type = 'file'
        file.size = file.fileSize
        file.extension = file.fileType

        delete file.fileName
        delete file.fileSize
        delete file.fileType
      })
      this.folders.forEach((folder) => {
        folder.id = folder.folderId
        folder.name = folder.folderName
        folder.extension = 'folder'
        folder.type = 'folder'
        delete folder.folderId
        delete folder.folderName
      })

      return [...this.folders, ...this.files]
    }
  },
  watch: {
    visible(value) {
      if (!value) {
        this.showCreateFolder = false
      }
      else {
        this.getData(this.parentId)
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/ .ant-modal-body {
  padding: 16px;
}

.save-item {
  display: flex;
  width: 100%;
  padding: 12px 0 12px 4px;
  overflow: hidden;

  &:hover {
    border-radius: 6px;
    background-color: #08ebe4f5;
    cursor: pointer;
  }
}

.breadcrumb {
  font-weight: 700;
  margin-bottom: 8px;
}

.file-cover {
  height: 24px;
  margin-right: 8px;
}

.file-name {
  width: 96%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.create-folder {
  display: flex;
  align-items: center;
  padding: 12px 0 12px 4px;

  &:hover {
    border-radius: 6px;
    background-color: #08ebe4f5;
    cursor: pointer;
  }

  .create-folder-input {
    flex: 1;
    margin-right: 12px;
  }

  .action-icon {
    font-size: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}

.disable {
  display: flex;
  width: 100%;
  padding: 12px 0 12px 4px;
  overflow: hidden;

  &:hover {
    cursor: not-allowed;
  }

  opacity: 0.4;
}

/deep/ .ant-modal-body {
  max-height: 655px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e9e9e9e9;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
  }
}
</style>
