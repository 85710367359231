<template>
  <div class="file-co-sharer">
    <a-modal v-model="visible" title="Shared file" :footer="null" :zIndex="9999">
      <a-form-model :model="form" :rules="rules" ref="modelRef">
        <a-form-model-item label="share name" prop="name">
          <a-input v-model="form.name" placeholder="Please enter a share name" allow-clear />
        </a-form-model-item>
        <a-form-model-item label="Shared description" prop="intro">
          <a-textarea v-model="form.intro" placeholder="Please enter a share description (optional)" :auto-size="{ minRows: 4 }" allow-clear />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleCreateCoShare">
            share
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>

export default {
  name: 'file-co-sharer',
  data() {
    return {
      visible: false,
      callback: undefined,
      form: {
        id: undefined,
        type: undefined,
        intro: undefined,
        name: undefined
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Please enter a share name',
            trigger: 'blur'
          }
        ]
      },
      name: undefined
    }
  },
  methods: {
    open(file, callback) {
      this.form.type = file.type
      this.form.id = file.id
      this.name = file.name
      this.callback = callback
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleCreateCoShare() {
      this.close()
      const key = 'co-share'
      const name = this.name
      this.$message.loading({ content: `${name} - Creating a share...`, key })
      const payload = this.form
      this.$store.dispatch('file/createCoShare', payload).then(response => {
        this.$message.success({ content: 'Created successfully', key, duration: 2 });
      }).finally(() => {
        this.close()
      })
    },
  }
}
</script>